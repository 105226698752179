export const environment:typeof import("./environment.dev").environment = {
    production: true,
    api: 'spesenapp-api-test.wgcdev.ch/spesenapp',
    region:'eu-central-1',
    auth: {
      clientId:'6cocrnpf5dsmse6861mghhh94o',
      userPoolId:'eu-central-1_1sDAElKOn',
      allowRegister:false,
      identityProviderSettings:{
          domainName:'wgc-spesenapptest.auth.eu-central-1.amazoncognito.com',
          redirectUri:'https://spesenapp-test.wgcdev.ch',
          providerName:'spesenapptestAAD',
          tokenMode:false
      }
    },
};